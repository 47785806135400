export default class Subscription {
  constructor() {
    this.clickFromChangePlanBtn = false;
    this.paymentMethod = { credit_card: 'stripe', bank: 'bank' };
    this.hasCard = false
    this.checkStatusChangePlan = false
    const me = this
    setTimeout(function() {
      me.popupConfirmAutoSetPaymentMethodBank();
    }, 2000)
  }

  async checkChangePayment(ele) {
    const paymentSelected = $(ele).val()
    const currentPaymentMethod = $('#js-store_all_data_to_handle').attr('data-current-payment-method');
    const newPlanId = Number($('#input_change_membership_plan').val());
    this.data_membership_plan = JSON.parse($('#js-store_all_data_to_handle').attr('data-membership_plan') || {});
    if (paymentSelected === 'bank') {
      const bodyPaymentEle = $(ele).parents('div.body-payment')
      await Shared.callAjax(
        Routes.check_can_payment_to_paid_owner_subscriptions_path(),
        {}
      )
      .done(function(data) {
        $('div#credit_card_info').hide();
        if (data.success) return
        bodyPaymentEle.find('input[value="stripe"]').prop('checked', true)
      })
    } else {
      $('div#credit_card_info').show();
    }
    $('input[name="payment_method"]:checked').parents('label').next().removeClass('d-none')
    $('input[name="payment_method"]:not(:checked)').parents('label').next().addClass('d-none')
    if (currentPaymentMethod === $('input[name="payment_method"]:checked').val()) {
      this.render_text_with_change_plan(newPlanId, currentPaymentMethod, $('input[name="payment_method"]:checked').val())
    } else {
      this.renderTextWithChangePaymentMethod(newPlanId, $('input[name="payment_method"]:checked').val())
    }
  }

  renderTextWithChangePaymentMethod(newPlanId, paymentMethod) {
    this.data_membership_plan.push(['supplier', 0])
    let text_price_with_bank_and_month;
    let text_price_with_bank_and_year;
    let text_price_with_stripe_and_month;
    let text_price_with_stripe_and_year;
    let text_with_info_plan_and_bank_and_month;
    let text_with_info_plan_and_bank_and_year;
    let text_with_info_plan_and_stripe_and_month;
    let text_with_info_plan_and_stripe_and_year;
    let newPlan = this.data_membership_plan.filter((plan) => { return plan[0] === newPlanId })[0]
    if (newPlan[2] === 0) {
      text_price_with_bank_and_month = '※プラン選択後に金額確定'
      text_price_with_bank_and_year = '※プラン選択後に金額確定'
      text_price_with_stripe_and_month = '※プラン選択後に金額確定'
      text_price_with_stripe_and_year = '※プラン選択後に金額確定'
      let plan_name = newPlan[1]
      plan_name = I18n.t(`enums.membership_plan.name.${plan_name}`)
      text_with_info_plan_and_bank_and_month = `${plan_name} プランは決済されません。`
      text_with_info_plan_and_bank_and_year = `${plan_name} プランは決済されません。`
      text_with_info_plan_and_stripe_and_month = `${plan_name} プランは決済されません。`
      text_with_info_plan_and_stripe_and_year = `${plan_name} プランは決済されません。`
    } else {
      const price = newPlan[2]
      text_price_with_bank_and_month = `${Shared.numberToCurrency(price)}円（税別）`
      text_price_with_bank_and_year = `${Shared.numberToCurrency(price * 10)}円（税別）`
      text_price_with_stripe_and_month = `${Shared.numberToCurrency(price)}円（税別）`
      text_price_with_stripe_and_year = `${Shared.numberToCurrency(price * 10)}円（税別）`
      if (paymentMethod === 'stripe') {
        text_with_info_plan_and_bank_and_month = '申込後に決済されます。'
        text_with_info_plan_and_bank_and_year = '申込後に決済されます。'
        text_with_info_plan_and_stripe_and_month = '更新後に決済されます。'
        text_with_info_plan_and_stripe_and_year = '更新後に決済されます。'
      } else {
        text_with_info_plan_and_bank_and_month =
          `申込後のお支払い期限は${gon.contract_info.date_with_bank_and_month}です。`
        text_with_info_plan_and_bank_and_year =
          `申込後のお支払い期限は${gon.contract_info.date_with_bank_and_year}です。`
        text_with_info_plan_and_stripe_and_month = ``
        text_with_info_plan_and_stripe_and_year = ``
      }

      if (newPlan[4]) {
        text_price_with_bank_and_month = `${Shared.numberToCurrency(0)}円　※キャンペーン中`
        text_price_with_bank_and_year = `${Shared.numberToCurrency(price * 10 * (newPlan[5] / 12))}円（税別）※キャンペーン中`
        text_price_with_stripe_and_month = `${Shared.numberToCurrency(0)}円　※キャンペーン中`
        text_price_with_stripe_and_year = `${Shared.numberToCurrency(price * 10 * (newPlan[5] / 12))}円（税別）※キャンペーン中`
        text_with_info_plan_and_bank_and_month = '更新後に決済されます。'
        text_with_info_plan_and_bank_and_year += '（6ヶ月分は通常料金が決済されます）'
        text_with_info_plan_and_stripe_and_year += '（6ヶ月分は通常料金が決済されます）'
      }
    }
    $('#text_price_with_bank_and_month').html(text_price_with_bank_and_month)
    $('#text_price_with_bank_and_year').html(text_price_with_bank_and_year)
    $('#text_price_with_stripe_and_month').html(text_price_with_stripe_and_month)
    $('#text_price_with_stripe_and_year').html(text_price_with_stripe_and_year)

    $('#text_with_info_plan_and_bank_and_month').html(text_with_info_plan_and_bank_and_month)
    $('#text_with_info_plan_and_bank_and_year').html(text_with_info_plan_and_bank_and_year)
    $('#text_with_info_plan_and_stripe_and_month').html(text_with_info_plan_and_stripe_and_month)
    $('#text_with_info_plan_and_stripe_and_year').html(text_with_info_plan_and_stripe_and_year)
  }

  requestRegisterPaid() {
    Shared.callAjax(
      Routes.register_member_paid_owner_subscriptions_path(),
      {}
    )
    .done(function(data) {
      if (data.register) {
        Popup.alert('銀行振込申請を行いました。')
        Shared.reloadPage()
      } else {
        Popup.alert(data.message)
      }
    })
    .fail(function(data) {
      if (data.responseJSON.message) Popup.alert(data.responseJSON.message)
      if (data.responseJSON.is_show_update_company_infor) {
        checkZipCode()
        $('#applicationBankTransferForm').modal('show')
      }
    })
  }

  checkFiledKatakana(ele, is_error) {
    let regex_katakana_name = /^[ァ-・ヽヾ゛゜ー ( )（）]+$/;
    if (!regex_katakana_name.test($(ele).val())) {
      $(ele).addClass('is-invalid').parent().css('border', '2px solid red');
      is_error = false
    } else {
      $(ele).removeClass('is-invalid').parent().css('border', '2px solid #4384b4');
    }
  }

  async handleSubmitUpdateInfo(event) {
    let isSubmit = true;

    if (
      (!$('#subscriptions_payment_term').val() &&
       gon.membership_plan.paid_ids.includes(Number($('#subscriptions_membership_plan_id').val()))
      ) || !$('#subscriptions_membership_plan_id').val()
       ) {
      $('#js-will_change_subscription_to').html('プランと支払周期を入力してください。')
      isSubmit = false;
    }
    if (!$('#subscriptions_payment_method').val() &&
        gon.membership_plan.paid_ids.includes(Number($('#subscriptions_membership_plan_id').val()))) {
      $('#js-will_change_payment_to').html('支払方法を入力してください。')
      isSubmit = false;
    }

    if ($('#js-will_change_credit_card_to').attr('data-customer-id') == 'false') {
      if ($('#subscriptions_payment_method').val() == 'stripe') {
        if ($('#subscriptions_credit_card').val() == '' || $('#subscriptions_credit_card').val() == undefined) {
          $('#js-will_change_credit_card_to').html('クレジットカード決算で支払方法を選択されましたので、<br/>クレジットカード情報を入力してください。');
          isSubmit = false
        } else {
          $('#js-will_change_credit_card_to').html('')
        }
      } else {
        $('#js-will_change_credit_card_to').html('')
      }
    }

    if ($('#subscriptions_payment_method').val() == 'bank') {
      if (!$('input.js-subscription_company_infor_invoice').toArray().every(function(ele) { return !!$(ele).val() })) {
        $('#company_infor_invoice_not_empty').addClass('text-danger-new')
          .html('銀行振込で支払方法が選択されましたので、<br/>銀行振込情報を入力してください。');
        isSubmit = false
      } else {
        $('#company_infor_invoice_not_empty').html('')
      }
    } else {
      $('#company_infor_invoice_not_empty').html('')
    }

    if($(".submit-subscription-update-info").data("can-submit") == "cannot") {
      isSubmit = false
      $("#remindCheckCode").modal("show")
    }

    event.preventDefault();
    if ($('input[name="subscriptions[password]"]').val() == '' || $('input[name="subscriptions[password]"]').val() == undefined) {
      isSubmit = false
      $('#wrongPasswordAlert').find('p').html('現在のパスワードを入力してください。')
      $('#wrongPasswordAlert').modal('show')
    }

    if (isSubmit) {
      const newPlan = Number($('#input_change_membership_plan').val());
      const paymentMethod = $('input[name="payment_method"]:checked').val()
      const paymentTerm = $(`input[name="payment-term_${paymentMethod}"]:checked`).val()
      const creditCard = $('#number_credit_card').val()
      const password = $('#subscriptions_password').val()
      const nameCreditCard = $('#name_credit_card').val()
      const paramsCard = creditCard.slice(creditCard.length - 4, creditCard.length)
      let data = await this.handleCheckChangePlan(newPlan, paymentTerm, paramsCard, password, paymentMethod, nameCreditCard)

      if (data.no_card) {
        this._handleNoCard()
      } else if (data.upgrade_from_supplier_plan) {
        this._upgradeFromSupplierPlan(data.current_plan_akapon)
      } else if (data.not_change_plan) {
        this.handleShowPopupLatePayment(data.invoice_delay, 'not_change', null)
      } else if (data.lack_of_information) {
        this.handleShowPopupLackOfInfo();
      } else if (data.password_incorrect) {
        $('#wrongPasswordAlert').find('p').html('現在のパスワードが間違っています。')
        $('#wrongPasswordAlert').modal('show');
      } else if (data.connect_error) {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      } else if (data.table.is_upgrade) {
        const invoiceDelayed = data.table.invoice_delay
        this.handleShowPopupLatePayment(invoiceDelayed, 'upgrade', data.table.payload)
      } else if (data.table.is_downgrade) {
        const invoiceDelayed = data.table.invoice_delay
        this.handleShowPopupLatePayment(invoiceDelayed, 'downgrade', data.table.payload)
      } else {
        this.submitUpdateContractInfo()
      }
    }
  }

  async handleCheckChangePlan(newPlanId, paymentTerm, credit_card, password, paymentMethod, nameCreditCard) {
    let data;
    await Shared.callAjax(
      Routes.handle_check_change_plan_owner_subscriptions_path(),
      {
        new_plan_id: newPlanId,
        payment_term: paymentTerm,
        credit_card: credit_card,
        password: password,
        payment_method: paymentMethod,
        credit_card_name: nameCreditCard,
        cancel_subscription: $('input#subscriptions_cancel_subscription').val(),
        campaign_code: $('input#campaign_code').val()
      }
    )
    .done(function(respond) {
      data = respond
    })
    .fail(function(respond) {
      data = respond
    })
    return data
  }

  fetchTextWithUpgradePlan(paymentMethod, data, textWithPaymentTerm, textWithPaymentMethod) {
    let amountText;
    let confirmText;
    let classCol = Shared.screenMobile() ? '' : 'col-3'
    if (data.discount_amount > 0) {
      amountText = `<span class="${classCol} p-0">差額決済額</span><span class="">：${Shared.numberToCurrency(data.amount)}円（税込）</span>
      <span class="${classCol} p-0">値引き額</span><span class="">：${Shared.numberToCurrency(data.discount_amount)}円 （対象割引率${data.discount_value}％）</span>
      <span class="${classCol} p-0">請求金額</span><span class="">：${Shared.numberToCurrency(data.amount_after_discount)}円（税込）</span>`
    } else {
      amountText = `<span class="${classCol} p-0">請求金額</span><span class="">：${Shared.numberToCurrency(data.amount)}円（税込）</span>`
    }
    if (data.upgrade_form_akapon_to_cc_plan) {
      confirmText = `アカポン会員からCreate Cloud会員へ変更します。
      変更後、アカポン会員へは戻れなくなります。
     上記の内容で更新してよろしいですか？`
    } else {
      confirmText = '上記の内容で契約情報を更新してよろしいですか？'
    }

    let number_default_card = $('#js-number-default-card').val();
    let name_default_card = $('#js-name-default-card').val();
    if (data.is_promotion) {
      amountText += `<br><span class="${classCol} p-0">値引額</span><span class="">：${Shared.numberToCurrency(data.promotion_price)}円（税込）※キャンペーン中</span>
      <span class="${classCol} p-0 font-weight-bold">値引後請求金額</span><span class="font-weight-bold">：${Shared.numberToCurrency(data.amount - data.promotion_price)}円</span>`
    }

    if (paymentMethod == 'stripe') {
      return `<div class="text-center h5 font-weight-normal mt-n5 ml-3 ${Shared.screenMobile() ? 'mb-n4' : 'mb-n5'}">
          <p class="rows text-left ${Shared.screenMobile() ? '' : 'pl-3'}" style="${Shared.screenMobile() ? '' : 'padding-left: 32% !important;'}">
          <span class="${classCol} p-0">プラン</span><span class="">：${data.plan_name}</span>
          ${amountText}
          <span class="${classCol} p-0">支払方法</span><span class="">：${textWithPaymentMethod} | ${textWithPaymentTerm}</span>
          <span class="${classCol} p-0">対象期間</span><span class="">：${data.time_line}</span>
          <span class="${classCol} p-0">クレジットカード情報</span><span class="">：${number_default_card ? `****${number_default_card}` : data.credit_card}</span>
          <span class="${classCol} p-0">クレジットカード名義名</span><span class="">：${name_default_card ? name_default_card : data.card_name}</span>
          ${
            !!data.next_payment_date_of_promotion ?
            `<span class="${classCol} p-0">次回請求日</span><span class="">：${I18n.l('date.formats.ja', data.next_payment_date_of_promotion)}</span>` :
            `<span class="${classCol} p-0">${data.upgrade_paid_plan ? '差額決済日' : '請求日'}</span><span class="">：${I18n.l('date.formats.ja', gon.date_current)}</span>`
          }
        </p>
        <span class="text-center d-block">${confirmText}</span>
      </div>
      `
    } else {
      let invoiceDate = new Date(gon.date_current);
      if (!data.upgrade_paid_plan) {
        invoiceDate.setMonth(invoiceDate.getMonth() + 1);
        invoiceDate.setDate(1);
      }
      return `<div class="text-center h5 font-weight-normal mt-n5 ml-3 ${Shared.screenMobile() ? 'mb-n4' : 'mb-n5'}">
          <p class="rows text-left ${Shared.screenMobile() ? '' : 'pl-3'}" style="${Shared.screenMobile() ? '' : 'padding-left: 32% !important;'}">
          <span class="${classCol} p-0">プラン</span><span class="">：${data.plan_name}</span>
          ${amountText}
          <span class="${classCol} p-0">支払方法</span><span class="">：${textWithPaymentMethod} | ${textWithPaymentTerm}</span>
          <span class="${classCol} p-0">対象期間</span><span class="">：${data.time_line}</span>
          ${
            !!data.next_payment_date_of_promotion ?
            `<span class="${classCol} p-0">次回請求日</span><span class="">：${I18n.l('date.formats.ja', data.next_payment_date_of_promotion)}</span>` :
            `<span class="${classCol} p-0">${data.upgrade_paid_plan ? '差額決済日' : '請求日'}</span><span class="">：${I18n.l('date.formats.ja', invoiceDate)}</span>`
          }
        </p>
        <span class="text-center d-block">${confirmText}</span>
      </div>
      `
    }
  }

  handleShowPopupLackOfInfo() {
    Popup.confirm(`<div class="fs-20 font-weight-bold line-height-1-6">未入力の会社情報があります。</div>※Create Cloudプランでは、住所などの会社情報が必須となります。`, {
      success: () => {
        $('#companyProfileModal').modal('show')
      },
      cancel: () => {},
      confirmText: '会社情報を更新する',
      cancelBtn: 'false',
      width: 'auto'
    })
  }

  updateProfile(event) {
    event.preventDefault();
    ProfilePage.validate(event, $(event.target).parents('form'))
  }

  async handleShowPopupUpgrade(data) {
    const paymentMethod = $('#subscriptions_payment_method').val()
    const paymentTerm = $(`input[name="payment-term_${paymentMethod}"]:checked`).val()
    const textWithPaymentTerm = paymentTerm == 'month' ? '月払' : '年払'
    const textWithPaymentMethod = paymentMethod == 'bank' ? '銀行振込' : 'クレジットカード決済'
    if (data.is_error_data) {
      Popup.alert(
        I18n.t('subscription.message_downgrade_error_data', {
          data_size: data.detail_error.max_data, next_plan: data.detail_error.new_plan_name
        }),
      )
      return
    }

    await Popup.confirm(
      this.fetchTextWithUpgradePlan(paymentMethod, data, textWithPaymentTerm, textWithPaymentMethod),
      {
        success: () => { this.submitUpdateContractInfo() },
        cancel: () => {},
        confirmText: 'OK',
        cancelBtn: 'false',
        width: '35rem'
      }
    )
  }

  async handleShowPopupLatePayment(data, action, payload) {
    if (!data) {
      this._handleShowPopupConfirmChangePlan(action, payload)
      return;
    }

    if (data.no_card) {
      this._handleNoCard()
      return;
    }

    const last4Card = $('#number_credit_card').val().slice(-4);
    await Popup.confirm(
      `<div class="text-center h5 font-weight-normal mt-n5 mb-n5 ml-3">
          <p>
          請求金額：${data.amount_to_currency}（税込）
          支払方法：${data.payment_method_text}
          対象期間：${data.period_date}
          クレジットカード情報：${!!last4Card ? `****${last4Card}` : data.last4_card}
          決済日：${I18n.l('date.formats.ja', gon.date_current)}
        </p>
        <span class="text-center d-block">上記の内容で契約情報を更新してよろしいですか？</span>
      </div>
      `,
      {
        success: () => {
          this._handleShowPopupConfirmChangePlan(action, payload)
        },
        cancel: () => {},
        confirmText: 'OK',
        cancelBtn: 'false',
        width: '35rem'
      }
    )
  }

  _upgradeFromSupplierPlan(is_akapon) {
    let planName = is_akapon ? `アカポン | FreeプランからCreate Cloud | Free` : '仕入先プランからFree'
    let confirmText;
    if (is_akapon) {
      confirmText = `<br>アカポン会員からCreate Cloud会員へ変更します。
      変更後、アカポン会員へは戻れなくなります。
     上記の内容で更新してよろしいですか？`
    } else {
      confirmText = '契約情報を更新してよろしいですか？'
    }
    Popup.confirm(
      `
        ${planName}プランへアップグレードします。
        ${confirmText}
      `,
      {
        success: () => {
          this.submitUpdateContractInfo()
        },
        cancel: () => {},
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto'
      }
    )
  }

  _handleShowPopupConfirmChangePlan(action, payload) {
    if (action === 'upgrade') {
      this.handleShowPopupUpgrade(payload)
    } else if (action === 'downgrade') {
      this.handleShowPopupDowngrade(payload)
    } else if (action === 'not_change') {
      this.submitUpdateContractInfo()
    }
  }

  handleShowPopupDowngrade(data) {
    if (!data.is_error) { this._showPopupConfirmDowngrade(data.detail_error); return }
    if ((data.detail_error.is_error_project && data.detail_error.is_error_data)) {
      const modalShow = $('#modal-error-downgrade-all')
      modalShow.find('.number-project-error').text(`${data.detail_error.exceed_project}個`)
      modalShow.find('.number-data-error').text(`${data.detail_error.exceed_data}`)
      modalShow.modal('show');
    } else if (data.detail_error.is_error_project) {
      const modalProject = $('#modal-error-downgrade-project')
      modalProject.find('.number-project-error').text(`${data.detail_error.exceed_project}個`)
      modalProject.modal('show');
    } else if (data.detail_error.is_error_task) {
      Popup.alert(
        I18n.t('subscription.message_downgrade_error_task', {
          project_size: data.detail_error.max_task, next_plan: data.detail_error.new_plan_name
        }),
      )
    } else if (data.detail_error.is_error_data) {
      const modalData = $('#modal-error-downgrade-data')
      modalData.find('.number-data-error').text(`${data.detail_error.exceed_data}`)
      modalData.modal('show');
    }
  }

  submitUpdateContractInfo(latePayment = false) {
    const me = this;
    if ($('#card_ids_temp_added').length != 0) {
      $('input#subscriptions_card_ids_will_added').val($('#card_ids_temp_added').val());
    }
    $('body').attr('data-has-changed', 'false');

    const formData = $('form#form_subscription_update_info').serialize();
    const ajaxCall = Shared.callAjax($('form#form_subscription_update_info').attr('action'), formData)
    ajaxCall.done(function(result) {
      if (result.success) {
        const message = latePayment ? 'クレジットカード決済が完了しました。' : '契約情報を更新しました。'
        if (result.client_secret) {
          me._showPopupNotifyVerifyPayment(result.client_secret)
        } else {
          if (result.show_popup_introduce_cc) {
            Popup.confirm(`
              <div class="text-center popup-intro-akapon-member"><div style="font-size: 24px; margin-bottom: 4px;">Create Cloudプランに変更されました。</div>Create Cloud機能をご利用頂けます。
              <div class="text-left" style="margin-left: 100px; font-size: 22px">
              ・複数プロジェクトの作成
              ・チケット管理
              ・見積書・注文・個数管理
              ・請求書・支払書管理
              ・赤入れ機能
              ・契約書作成・締結機能　など</div>
              <div style="font-size: 24px">※詳しくは<a href="https://createcloud.jp/support/" target="_blank" class="text-underline-hover">サポート</a>をご覧ください。</div></div>`, {
              cancelBtn: 'false', confirmText: 'OK',
              success: () => {
                window.location.href = Routes.owner_total_info_path()
              }
            })
            $(".popup-intro-akapon-member").parent().parent().css("border", "5px solid #2467B6")
          } else {
            Popup.confirm(message, {
              cancelBtn: 'false', confirmText: 'OK',
              success: () => {
                if(result.cancel_supscription){
                  window.location.href = Shared.lpSite()
                } else {
                  window.location.href = Routes.owner_total_info_path()
                }
              }
            })
          }
        }
      } else {
        const pagymentMethod = $('#subscriptions_payment_method').val()
        let planName = '';
        let data_membership_plan = $('#js-store_all_data_to_handle').attr('data-membership_plan');
        let current_membership = Number($('#js-btn_show_modal_confirm_change_subscription').attr('data-current-member-plan-id'));
        let new_membership = Number($('#input_change_membership_plan').val())
        if (data_membership_plan) {
          data_membership_plan = JSON.parse(data_membership_plan);
        }
        if (data_membership_plan && current_membership != new_membership) {
          let newPlan = data_membership_plan.filter((plan) => { return plan[0] === new_membership })
          planName = newPlan[0][1]
        }
        let message = pagymentMethod === 'bank' ? `${I18n.t(`enums.membership_plan.name.${planName}`)}プランは銀行振込決済をご利用できません。<br>「支払方法」よりクレジットカードの選択及びご登録をお願い致します。` : `現在の支払方法で決済できませんでした。<br>恐れ入りますが、別のクレジットカードをご利用ください。<br><br>[ERR: ${result.message}]`
        if (result.message == '限度額をオーバーしています') {
          message = `ご決済予定の金額が、<a href="https://paid.jp/v/contents/pre/buyer/" class="text-underline text-white" target="_blank">Paid</a>のご利用可能額をオーバーした為<br>${I18n.t(`enums.membership_plan.name.${planName}`)}プランのご決済ができませんした。<br>※ご利用可能額については、<a href="https://member.createcloud.jp/contact_us" class="text-underline text-white" target="_blank">弊社へお問合わせ</a>頂くか<br><a href="https://paid.jp/v/do/member/inquiry/form" class="text-underline text-white" target="_blank">Paid管理画面</a>より、お問合わせください。`
        }
        Popup.confirm(message, {
          cancelBtn: 'false', confirmText: 'OK',
          success: () => {
            $('#CreditCard').modal('show')
          },
          width: 'auto'
        })
      }
    })
  }

  _showPopupNotifyVerifyPayment(client_secret) {
    const message = `
      契約情報更新の仮登録が完了しました。

      ご登録のメールアドレス宛にクレジットカード会社から
      決済についての連絡メールが届きます。
      承認が完了したあと、契約情報が更新されます。
    `
    Popup.confirm(message, {
      cancelBtn: 'false', confirmText: 'OK',
      success: () => {
        setTimeout(function() { Shared.loadingIndicator() }, 50)
        const stripe = Stripe(gon.stripe_publishable_key);
        stripe.confirmCardPayment(client_secret);
        setTimeout(function() { $('.preloader').fadeOut() }, 2000);
      },
      width: 'auto'
    })
  }

  _handleNoCard() {
    Popup.alert('カードがありません。<br>カードを追加してください。')
    $('#CreditCard').modal('show')
  }

  _showPopupConfirmDowngrade(data) {
    let confirmText;
    if (data.upgrade_form_akapon_to_cc_plan) {
      confirmText = `<br><br>アカポン会員からCreate Cloud会員へ変更します。<br>
      変更後、アカポン会員へは戻れなくなります。 <br>
     上記の内容で更新してよろしいですか？`
    } else {
      confirmText = '<br>契約情報を更新してよろしいですか？'
    }
    Popup.confirm('',{
      description:
        `${I18n.t('subscription.message_downgrade',
            {
              day_remaining: data.day_remaining, new_plan: data.new_plan_name,
              current_plan: data.current_plan_name
            }
          )
        } ${confirmText}`,
      success: () => { this.submitUpdateContractInfo() },
      cancel: () => {},
      confirmText: 'OK',
      // cancelBtn: 'false',
      width: 'auto'
    })
  }

  handleShowChangePlanModal(ele) {
    $('#js-submit_credit_card').attr('from-modal', '')
    if (gon.contract_info.company_status === 'payment_delayed') {
      Popup.confirm('入金遅延があるため、プランを変更できません。<br>支払方法を変更してください。', {
        success: () => {
          this._showAddCardOrChangePlan()
        },
        cancel: () => { this._showAddCardOrChangePlan() },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto'
      })
      return
    }
    if (gon.contract_info.company_status !== 'payment_delayed') {
      $('#changePlanAndPaymentTermModal').modal('show')
    }
  }

  async handleCancelCompany() {
    const me = this;
    if (gon.contract_info.company_status === 'payment_delayed') {
      this.showPopupWarningWhenPaymentDelayed();
    } else {
      await Shared.callAjax(Routes.check_cancel_company_owner_subscriptions_path()).done(function(result) {
        if (result.not_in_progress) {
          $('#exampleModalCenter1').modal('show')
        } else {
          me.showPopupWarningWhenUnpaidPartner(result.in_progress);
        }
      })
    }
  }

  showPopupWarningWhenPaymentDelayed() {
    if (gon.current_subscription.payment_method === this.paymentMethod['credit_card']) {
      $('#WarningPaymentDelayWithStripe').modal('show')
    } else if (gon.current_subscription.payment_method === this.paymentMethod['bank']) {
      $('#WarningPaymentDelayWithBank').modal('show')
    }
  }

  showPopupWarningWhenUnpaidPartner(data) {
    let orderList = '';
    let path = '';
    if (data.unpaid_payment_size > 0) {
      path = Routes.owner_list_invoice_payments_path({q: { status_in: ['not_payment', 'residual', 'unconfirmed'] }})
      orderList += `・<a href="${path}" target="_blank" class="white-text custom-underline text-underline line-height-1-6">未払いの支払書が${data.unpaid_payment_size}件存在します。</a><br>`
    }
    if (data.backlog_of_quotations.length > 0) {
      path = Routes.owner_owner_received_quotations_list_path({query: { id_in: data.backlog_of_quotations }})
      orderList += `・<a href="${path}" target="_blank" class="white-text custom-underline text-underline line-height-1-6">作業中の発注が${data.backlog_of_quotations.length}件存在します。</a><br>`
    }
    if (data.effort_not_release_ids.length > 0) {
      path = Routes.undelivered_works_efforts_path({ quantity_ids: data.effort_not_release_ids })
      orderList += `・<a href="${path}" target="_blank" class="white-text custom-underline text-underline line-height-1-6">未納品の作業が${data.effort_not_release_ids.length}件存在します。</a>`
    }
    Popup.confirm(
      `<div class='font-weight-normal h5'>取引中の書類または作業が存在する為、解約できません。
        ${orderList}
        上記を完了（削除）すると、解約が可能になります。
      </div>`,
      {
        success: () => {},
        cancel: () => {},
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto',
      }
    )
  }

  submitNewPlan(ele) {
    let new_membership = Number($('#input_change_membership_plan').val());
    if (gon.membership_plan.free_id.includes(new_membership)) {
      this.hasChangeSetting()
      return;
    }
    this.checkStatusChangePlan = true

    if (!$('input#subscriptions_payment_method').val()) {
      $('#ChangePaymentMethod').modal('show')
    } else if ((gon.current_subscription.payment_method === this.paymentMethod['credit_card'] ||
      $('input#subscriptions_payment_method').val() === this.paymentMethod['credit_card']) &&
      (!gon.current_subscription.has_card && !$('#subscription_credit_card_number').val() && !this.hasCard)
    ) {
      $('#CreditCard').modal('show')
    }

    this.hasChangeSetting()
  }

  _showAddCardOrChangePlan() {
    this.clickFromChangePlanBtn = true;
    if (this.hasCard || gon.current_subscription.has_card) {
      $('#changePlanAndPaymentTermModal').modal('show')
    } else {
      $('#CreditCard').modal('show')
    }
  }

  handleShowChangePaymentMethod(ele) {
    const me = this;
    setTimeout(function() {
      if ($('input#subscriptions_payment_method').val() === me.paymentMethod['credit_card']) {
        if (gon.current_subscription.has_card || !!$('#subscription_credit_card_number').val() || me.hasCard) {
          if (me.clickFromChangePlanBtn) {
            $('#changePlanAndPaymentTermModal').modal('show')
          }
        } else {
          $('#CreditCard').find('button.btn-back-credit').attr('from-modal', 'ChangePaymentMethod')
          $('#CreditCard').modal('show')
        }
      } else {
        if (me.clickFromChangePlanBtn) {
          $('#changePlanAndPaymentTermModal').modal('show')
        }
      }
    }, 300)
  }

  handleConfirmCardModal(ele) {
    const $ele = $(ele);
    const me = this
    const fromModal = $ele.parents('#CreditCardConfirm').attr('from-modal')
    const data = {
      card_number: $('#number_credit_card').val(),
      expire: [$('#year_expire_credit_card').val(), $('#month_expire_credit_card').val()],
      security_code: $('#security_code_credit_card').val(),
      card_holder: $('#name_credit_card').val()
    }
    const card_ids_temp_added = $('#card_ids_temp_added').val();
    const url = Routes.owner_cards_path()
    const callAjax = Shared.callAjax(url, { credit_card: data, card_ids_temp_added: card_ids_temp_added }, 'html')
    callAjax.done(function(result) {
      $('#cards-list-section').html(result);

      me.creditCardInfoChangedText();
      me.setCardDefaultSelected();
      me.handleRemoveCardsTemporaryDelete();

      $('body').attr('data-has-changed', 'true');
      if (fromModal == 'creditCardSelect') {
        feather.replace();
        $(`#${fromModal}`).modal('show')
      } else {
        const isCardDefaultInvalid = $('input#js-card-is-default-and-invalid').val()
        if (me.clickFromChangePlanBtn && !isCardDefaultInvalid) {
          $('#changePlanAndPaymentTermModal').modal('show')
        }
      }
      $('#WarningPaymentDelayWithBank').modal('hide')
      $('#WarningPaymentDelayWithStripe').modal('hide')
      const numberOfCard = parseInt($('p#number-of-card-label-text').text()) || 0;
      const numberDefaultCard = $('input#js-number-default-card').val()
      $('p#number-of-card-label-text').text(`${numberOfCard + 1}個のクレジットカード情報が設定されています。`)
      $('a#card-number-label-btn-text').text(`末尾●●●●${numberDefaultCard}`)
      $('#js-will_change_credit_card_to').attr('data-customer-id', 'true')
      me._resetCardForm();
      if (!$('#subscriptions_payment_method').val() || $('#subscriptions_payment_method').val() !== me.paymentMethod['credit_card']) {
        me.popupConfirmAutoSetPaymentMethodStripe()
      }
      setTimeout(function() {
        Shared.callAjax(Routes.check_confirm_setup_intents_owner_cards_path(), {}, 'json', 'GET').done(function(resp) {
          if (resp.has_setup_intents) {
            const stripe = Stripe(gon.stripe_publishable_key);
            if (resp.client_secret) {
              stripe.confirmCardSetup(resp.client_secret)
            } else {
              me._showPopupInformToUserNeedVerifyCard()
            }
          }
        })
      }, 50)
    })
    .fail(function(result) {
      const message = `現在の支払方法で決済できませんでした。<br>恐れ入りますが、別のクレジットカードをご利用ください。<br><br>[ERR: ${result.responseText}]`
      Popup.alert(message, '', '', { popupCustomClass: 'w-auto' })
      $('#CreditCard').modal('show')
    })
  }

  popupConfirmAutoSetPaymentMethodStripe() {
    if (gon.current_subscription?.free_plan) { return }

    Popup.confirm(`支払方法をクレジットカード（月払い）<br>に設定しますか？`,
    {
      success: () => {
        this.autoSetPaymentMethod(this.paymentMethod['credit_card'])
      },
      cancel: () => { return },
      confirmText: 'OK',
      cancelText: '後にする',
      width: 'auto'
    })
  }

  popupConfirmAutoSetPaymentMethodBank() {
    if (
        gon.contract_info?.is_skip_warning_set_bank_method ||
        gon.contract_info?.is_change_bank_to_credit ||
        gon.current_subscription?.free_plan ||
        $(`input:radio[value='${this.paymentMethod["bank"]}'][name='payment_method']`).length == 0 ||
        $(`input:radio[value='${this.paymentMethod["bank"]}'][name='payment_method']`).prop("disabled") ||
        (!!$("#subscriptions_payment_method").val() &&
        $("#subscriptions_payment_method").val() === this.paymentMethod["bank"])
    ) {
      return;
    }
    let msg = ''
    if(Shared.screenMobile()) {
      msg = '銀行振込の承認が完了しました。<br>支払方法を銀行振込に変更する場合は<br>「現在のパスワード」をご入力のうえ、<br>「この内容で更新する」から変更されます。'
    } else {
      msg = '銀行振込の承認が完了しました。<br>支払方法を銀行振込に変更する場合は<br>「現在のパスワード」をご入力のうえ、「この内容で更新する」から変更できます。'
    }
    Popup.confirm(
      msg,
      {
        success: () => {
          this.autoSetPaymentMethod(this.paymentMethod["bank"]);
        },
        cancel: () => {
          Shared.callAjax(Routes.skip_warning_set_bank_method_owner_subscriptions_path())
        },
        confirmText: "OK",
        cancelText: "後にする",
        width: "auto",
        mustCancel: true,
      }
    );
  }

  autoSetPaymentMethod(payment_method) {
    $(`input:radio[value='${payment_method}'][name='payment_method']`).prop('checked', true).trigger('click')
    $('#submit_change_payments').trigger('click');
  }

  handleNotChangePlanBtn(event) {
    event.preventDefault();
    if (gon.current_subscription.free_plan && !this.checkStatusChangePlan) {
      this.clickFromChangePlanBtn = true;
    } else {
      this.clickFromChangePlanBtn = false;
    }
  }

  addNewCreditCard(event) {
    event.preventDefault();
    $('#creditCardSelect').modal('hide')
    $('#js-submit_credit_card').attr('from-modal', 'creditCardSelect')
    $('#CreditCard').find('button.btn-back-credit').attr('from-modal', $(event.target).parents('div.modal').attr('id'))
    $('#CreditCard').modal('show')
  }

  handleBtnBackCreditCard(ele) {
    const modal = $(ele).attr('from-modal')
    if (modal === 'creditCardSelect') {
      this.showCardsList(this)
    }
    $(`#${modal}`).modal('show')
  }

  handleSetDefaultCard(ele) {
    const $ele = $(ele);
    const me = this;
    const cardId = $ele.attr('data-card-id');

    $('a#card-number-label-btn-text').text(`末尾●●●●${$ele.attr('data-card-last4')}`)
    $('.trash-area-cards').removeClass('d-none');
    $(`#btn-delete-of-card_${cardId}`).addClass('d-none')
    $('span.set-default-card').addClass('icon-uncheck').removeClass('icon-check').html('');
    $ele.removeClass('icon-uncheck');
    $ele.html('&#10004;')
    $ele.addClass('icon-check')
    $('#subscriptions_default_card_id').val(ele.dataset.cardId);

    $('#js-number-default-card').val($ele.attr('data-card-last4'));
    $('#js-name-default-card').val($ele.attr('data-card-name'));

    this.creditCardInfoChangedText();
    this.hasChangeSetting();
  }

  creditCardInfoChangedText() {
    if (
      $('#card_ids_temp_added').length != 0 && $('#card_ids_temp_added').val() != '[]' ||
      $('#subscriptions_card_ids_will_be_deleted').length != 0 && $('#subscriptions_card_ids_will_be_deleted').val() != '[]' ||
      $('#subscriptions_default_card_id').data('original-default-card-id') != $('#subscriptions_default_card_id').val()
    ) {
      $('#js-credit-card-info-changed').html('変更後更新されます。');
    } else {
      $('#js-credit-card-info-changed').html('');
    }
  }

  showCardsList(ele) {
    this.clickFromChangePlanBtn = false;
    const url = Routes.owner_cards_path()
    var me = this;

    let card_ids_temp_added = $('#card_ids_temp_added').val();
    const callAjax = Shared.callAjax(url, {card_ids_temp_added: card_ids_temp_added}, 'html', 'GET')
    callAjax.done(function(result) {
      $('#cards-list-section').html(result);
      feather.replace();
      me.handleRemoveCardsTemporaryDelete();
      me.setCardDefaultSelected();
      $('#creditCardSelect').modal('show')
    });
  }

  handleRemoveCardsTemporaryDelete() {
    let card_id_will_deleted = JSON.parse($('#subscriptions_card_ids_will_be_deleted').val());
    card_id_will_deleted.forEach((cardId) => {
      $(`span.set-default-card[data-card-id=${cardId}]`).closest('.credit-data').remove();
    });
  }

  setCardDefaultSelected() {
    let subscriptions_default_card_id = $('#subscriptions_default_card_id').val();
    if (!!subscriptions_default_card_id) {
      const $ele = $(`span.set-default-card[data-card-id=${subscriptions_default_card_id}]`);
      const div_credit_data = $ele.closest('div.credit-data').find('span.set-default-card');

      $('#js-number-default-card').val(div_credit_data.data('card-last4'));
      $('#js-name-default-card').val(div_credit_data.data('card-name'));

      $('.trash-area-cards').removeClass('d-none');
      $(`#btn-delete-of-card_${subscriptions_default_card_id}`).addClass('d-none')
      $('span.set-default-card').addClass('icon-uncheck').removeClass('icon-check').html('');
      $ele.removeClass('icon-uncheck');
      $ele.html('&#10004;')
      $ele.addClass('icon-check')
    }
  }

  handleDeleteCard(ele, cardId) {
    const $ele = $(ele);
    var me = this;
    const cardNumber = $ele.attr('data-card-last4');
    const cardName = $ele.attr('data-card-name');
    const cardExpMonth = $ele.attr('data-card-exp-month');
    const cardExpYear = $ele.attr('data-card-exp-year');
    Popup.confirm(`
        <div class="h5 font-weight-normal  mt-n4 mb-n4">
          クレジットカード番号　****${cardNumber}
          クレジットカード名義名　${cardName}
          有効期限　${cardExpMonth}月${cardExpYear}年<br>
          上記情報を削除してよろしいですか？
        </div>
      `, {
        success: () => {
          let idsCard = $('#subscriptions_card_ids_will_be_deleted').val();
          let idsCardArray = JSON.parse(idsCard);
          idsCardArray.push(cardId);
          $('#subscriptions_card_ids_will_be_deleted').val(`[${idsCardArray}]`);

          let cardIdsWillAdded = JSON.parse($('#card_ids_temp_added').val());
          $('#card_ids_temp_added').attr('value', JSON.stringify(cardIdsWillAdded.filter(id => id != parseInt(cardId))));
          me.creditCardInfoChangedText();
          $ele.closest('.credit-data').remove();
          this.hasChangeSetting();
        },
        cancel: () => { return },
        confirmText: 'OK',
        cancelText: '戻る',
        width: '35rem'
      }
    )
  }

  _resetCardForm() {
    this.hasCard = true;
    $('#number_credit_card').val('');
    $('#year_expire_credit_card').val($("#year_expire_credit_card option:first").val())
    $('#month_expire_credit_card').val($("#month_expire_credit_card option:first").val())
    $('#security_code_credit_card').val('')
    $('#name_credit_card').val('')
  }

  checkErrorOfRegexKatana(value) {
    const regex_katakana_name = /^[ァ-・ヽヾ゛゜ー ( )（）]+$/;
    return regex_katakana_name.test(value)
  }

  async nextInputInfoPaid(ele) {
    const regex_phone_number = /\d[0-9]{0,9}-[0-9]{1,9}-[0-9]{1,9}$/
    const email_regex = /(\b[^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
    const postalCodeRegex =/(^[0-9]{3}-[0-9]{4}$)/;
    const form = $(ele).parents('form#new_company_info_bank_transfer');
    let isCheck = true
    const listInputKatakana = [
      'company_info_bank_transfer_first_name_kana_of_ceo', 'company_info_bank_transfer_last_name_kana_of_ceo',
      'company_info_bank_transfer_company_name_kana', 'company_info_bank_transfer_first_name_kana_of_pic',
      'company_info_bank_transfer_last_name_kana_of_pic'
    ]
    let isInputBlank = false
    let isKatanaError = false
    let isPhoneError = false
    let isInputMaxLength = false
    await form.find("input[type!='hidden']").not('.not_required').toArray().forEach(input => {
      const inputValue = $(input).val()
      if(inputValue.trim() === '') {
        isCheck = false
        isInputBlank = true
        Shared.showInputError($(input), 'この項目は未入力です。')
      } else if (listInputKatakana.includes($(input).attr('id')) && !this.checkErrorOfRegexKatana(inputValue)) {
        isCheck = false
        isKatanaError = true
        Shared.showInputError($(input), '全角カナを50文字以内になるように入力してください。')
      } else if ($(input).attr('id') === 'company_info_bank_transfer_mobile_number' &&
                 (!regex_phone_number.test(inputValue) || inputValue.length > 25)) {
        isCheck = false
        isPhoneError = true
        Shared.showInputError($(input), '半角英数とハイフンで25文字以内になるように入力してください。')
      } else if ($(input).attr('id') === 'company_info_bank_transfer_email' && !email_regex.test(inputValue)) {
        isCheck = false
        Shared.showInputError($(input), 'メールアドレスのフォーマットが間違っています。')
      } else if ($(input).attr('id') === 'company_info_bank_transfer_postal_code' &&
                 !postalCodeRegex.test(inputValue)) {
        isCheck = false
        Shared.showInputError($(input), '郵便番号は正しくありません。<br>会社情報を更新してください。')
      } else if (inputValue.length > 50) {
        isCheck = false
        isInputMaxLength = true
        Shared.showInputError($(input), '50文字以内になるように入力してください。')
      } else {
        Shared.removeClassInInputError($(input))
      }
    })
    checkZipCode()
    if (isInputBlank) toastr.error('この項目は未入力です。', '', { closeButton: true, progressBar: true });
      if (isInputMaxLength) toastr.error('50文字以内になるように入力してください。', '', { closeButton: true, progressBar: true });
      if (isPhoneError) {
        toastr.error(
          '半角英数とハイフンで25文字以内になるように入力してください。',
          '', { closeButton: true, progressBar: true }
        );
      }
      if (isKatanaError) {
        toastr.error(
          '全角カナを50文字以内になるように入力してください。',
          '', { closeButton: true, progressBar: true }
        );
      }

    if (!isCheck) return
    form.find('input').attr('readonly', true);
    form.find('div#list-button-step1').find('button').addClass('d-none')
    form.find('div#list-button-step2').find('button').removeClass('d-none')
    form.find('#text_header_modal_paid_1').addClass('d-none')
    form.find('#text_header_modal_paid_2').removeClass('d-none')
    $('#applicationBankTransferForm').modal('hide')
    setTimeout(() => {
      $('#applicationBankTransferForm').modal('show');
    }, 500)
  }

  backToInputCompanyInfoBank(ele) {
    const form = $(ele).parents('form#new_company_info_bank_transfer');
    form.find('input').removeAttr('readonly');
    form.find('div#list-button-step2').find('button').addClass('d-none')
    form.find('div#list-button-step1').find('button').removeClass('d-none')
    form.find('#text_header_modal_paid_1').removeClass('d-none')
    form.find('#text_header_modal_paid_2').addClass('d-none')
    setTimeout(() => {
      $('#applicationBankTransferForm').modal('show');
    }, 500)
  }

  saveCompanyInfoBank(ele) {
    const form = $('form#new_company_info_bank_transfer');
    $(ele).attr('disabled', true)
    const formData = $(form[0]).serializeJSON();
    const ajaxCall = Shared.callAjax(form.attr('action'), formData)
    ajaxCall.done(function (result) {
      if (result.success) {
        $('#applicationBankTransferForm').modal('hide')
        Popup.alert('銀行振込申請が完了しました。')
        $('#text-with-status-company-in-paid').html('銀行振込審査中です。')
      } else {
        Popup.alert(result.message)
        $(ele).removeAttr('disabled')
      }
    })
  }

  validatePostalCodeAndPhone(event) {
    const ele = $(event.target)
    $(ele).val($(ele).val().replace(/[^0-9\-]/g,''));
  }

  render_text_with_change_plan(new_plan_index, currentPaymentMethod, newPaymentMethod) {
    this.data_membership_plan = JSON.parse($('#js-store_all_data_to_handle').attr('data-membership_plan') || {});
    const currentPaymentTerm = $('#ChangePaymentMethod').data('payment-term')
    this.data_membership_plan.push([4, 'supplier', 0])
    let text_price_with_bank_and_month;
    let text_price_with_bank_and_year;
    let text_price_with_stripe_and_month;
    let text_price_with_stripe_and_year;
    let text_with_info_plan_and_bank_and_month;
    let text_with_info_plan_and_bank_and_year;
    let text_with_info_plan_and_stripe_and_month;
    let text_with_info_plan_and_stripe_and_year;
    let newPlan = this.data_membership_plan.filter((plan) => { return plan[0] === new_plan_index })[0]
    let plan_name = newPlan[1]
    if (newPlan[2] === 0) {
      text_price_with_bank_and_month = '※プラン選択後に金額確定'
      text_price_with_bank_and_year = '※プラン選択後に金額確定'
      text_price_with_stripe_and_month = '※プラン選択後に金額確定'
      text_price_with_stripe_and_year = '※プラン選択後に金額確定'
      plan_name = I18n.t(`enums.membership_plan.name.${plan_name}`)
      let text = this.fetchtextInfoForCurrentMethodAndPlanFree(
        currentPaymentMethod, currentPaymentTerm, 'bank', 'month', plan_name
      )
      text_with_info_plan_and_bank_and_month = text
      text = this.fetchtextInfoForCurrentMethodAndPlanFree(
        currentPaymentMethod, currentPaymentTerm, 'bank', 'year', plan_name
      )
      text_with_info_plan_and_bank_and_year = text
      text = this.fetchtextInfoForCurrentMethodAndPlanFree(
        currentPaymentMethod, currentPaymentTerm, 'stripe', 'month', plan_name
      )
      text_with_info_plan_and_stripe_and_month = text
      text = this.fetchtextInfoForCurrentMethodAndPlanFree(
        currentPaymentMethod, currentPaymentTerm, 'stripe', 'year', plan_name
      )
      text_with_info_plan_and_stripe_and_year = text
    } else {
      const price = newPlan[2]
      text_price_with_bank_and_month = `${Shared.numberToCurrency(price)}円（税別）`
      text_price_with_bank_and_year = `${Shared.numberToCurrency(price * 10)}円（税別）`
      text_price_with_stripe_and_month = `${Shared.numberToCurrency(price)}円（税別）`
      text_price_with_stripe_and_year = `${Shared.numberToCurrency(price * 10)}円（税別）`
      if (currentPaymentMethod === newPaymentMethod) {
        let text = this.fetchTextForCurrentPaymentMethod(currentPaymentMethod, currentPaymentTerm, 'bank', 'month')
        text_with_info_plan_and_bank_and_month =
          `${text}次回のお支払期限は${gon.contract_info.date_with_bank_and_month}です。`
        text = this.fetchTextForCurrentPaymentMethod(currentPaymentMethod, currentPaymentTerm, 'bank', 'year')
        text_with_info_plan_and_bank_and_year =
          `${text}次回のお支払期限は${gon.contract_info.date_with_bank_and_year}です。`
        text = this.fetchTextForCurrentPaymentMethod(currentPaymentMethod, currentPaymentTerm, 'stripe', 'month')
        text_with_info_plan_and_stripe_and_month =
          `${text}次回決済日は${gon.contract_info.date_with_stripe_and_month}です。`
        text = this.fetchTextForCurrentPaymentMethod(currentPaymentMethod, currentPaymentTerm, 'stripe', 'year')
        text_with_info_plan_and_stripe_and_year =
          `${text}次回決済日は${gon.contract_info.date_with_stripe_and_year}です。`
      } else {
        if (newPaymentMethod === 'stripe') {
          text_with_info_plan_and_bank_and_month = '申込後に決済されます。'
          text_with_info_plan_and_bank_and_year = '申込後に決済されます。'
          text_with_info_plan_and_stripe_and_month = '更新後に決済されます。'
          text_with_info_plan_and_stripe_and_year = '更新後に決済されます。'
        } else {
          text_with_info_plan_and_bank_and_month =
            `申込後のお支払い期限は${gon.contract_info.date_with_bank_and_month}です。`
          text_with_info_plan_and_bank_and_year =
            `申込後のお支払い期限は${gon.contract_info.date_with_bank_and_year}です。`
          text_with_info_plan_and_stripe_and_month = ``
          text_with_info_plan_and_stripe_and_year = ``
        }
        if (newPlan[4]) {
          text_price_with_bank_and_month = `${Shared.numberToCurrency(0)}円　※キャンペーン中`
          text_price_with_bank_and_year = `${Shared.numberToCurrency(price * 10 * (newPlan[5] / 12))}円（税別）※キャンペーン中`
          text_price_with_stripe_and_month = `${Shared.numberToCurrency(0)}円　※キャンペーン中`
          text_price_with_stripe_and_year = `${Shared.numberToCurrency(price * 10 * (newPlan[5] / 12))}円（税別）※キャンペーン中`
          text_with_info_plan_and_bank_and_month = '更新後に決済されます。'
          text_with_info_plan_and_bank_and_year += '（6ヶ月分は通常料金が決済されます）'
          text_with_info_plan_and_stripe_and_year += '（6ヶ月分は通常料金が決済されます）'
        }
      }
    }
    $('#text_price_with_bank_and_month').html(text_price_with_bank_and_month)
    $('#text_price_with_bank_and_year').html(text_price_with_bank_and_year)
    $('#text_price_with_stripe_and_month').html(text_price_with_stripe_and_month)
    $('#text_price_with_stripe_and_year').html(text_price_with_stripe_and_year)

    $('#text_with_info_plan_and_bank_and_month').html(text_with_info_plan_and_bank_and_month)
    $('#text_with_info_plan_and_bank_and_year').html(text_with_info_plan_and_bank_and_year)
    $('#text_with_info_plan_and_stripe_and_month').html(text_with_info_plan_and_stripe_and_month)
    $('#text_with_info_plan_and_stripe_and_year').html(text_with_info_plan_and_stripe_and_year)
  }

  fetchtextInfoForCurrentMethodAndPlanFree(currentPaymentMethod, currentPaymentTerm, payment_method, payment_term, plan_name) {
    if (payment_method === currentPaymentMethod && payment_term === currentPaymentTerm) {
      return '現在利用中の支払方法です。'
    } else {
      return `${plan_name}プランは決済されません。`
    }
  }

  fetchTextForCurrentPaymentMethod(currentPaymentMethod, currentPaymentTerm, payment_method, payment_term) {
    if (payment_method === currentPaymentMethod && payment_term === currentPaymentTerm) {
      return '現在利用中の支払方法です<br>'
    } else {
      return ''
    }
  }

  checkCampaignCode(action) {
    var content = ""
    var parent = $(".group-apply-campaign-code");
    var campaign_code = parent.find("#campaign_code").val()
    const me = this;
    Shared.callAjax(Routes.check_code_owner_campaigns_path(), { campaign_code: campaign_code }).done(function(result) {
      if(result.data.status == "invalid") {
        content = `<p>このキャンペーンコードは無効です。</p>`
        if(action === 'register') {
          me._setCampaignCode('')
        }
      } else if(result.data.status == "expired") {
        content = `<p>このキャンペーンコードの期限は<br>${result.data.campaign.expired_date}で終了している為<br>ご利用頂けません。</p>`
      } else if(result.data.status == "duplicate") {
        content = `<p>このキャンペーンコードは既に適用しています。</p>`
      } else if (result.data.status == 'apply_your_self') {
          content =
            `<p>
              このキャンペーンコード<span class="d-inline-block">は無効です。</span><br>
              加盟店が「自身で作成したキャンペーンコード」<span class="d-inline-block">を利用する場合</span><br>
              1件以上の有料会員を保有している事が条件になります。<br>
              <a href="https://createcloud.jp/partner/" target="_blank">
              加盟店が自分のアカウントでキャンペーンコード<span class="d-inline-block">を利用する時</span>
              </a>
            </p>`
      } else {
        if(result.data.current_campaign.discount_value && result.data.current_campaign.expired_date) {
          $(".current_campaign_expired_date").html(`・有効期限：${result.data.current_campaign.expired_date}まで`)
          $(".current_campaign_discount_value").html(`・割引率：${result.data.current_campaign.discount_value}%`)
          $(".new_campaign_expired_date").html(`・有効期限：${result.data.campaign.expired_date}まで`)
          $(".new_campaign_discount_value").html(`・割引率：${result.data.campaign.discount_value}%`)
          $("#showCampaignCodeReplace").modal("show")
        } else {
          content = `<p>適用可能なキャンペーンコードです。<br>有料プランをご利用時に、<br>利用料から${result.data.campaign.discount_value}％値引きされます。<br>有効期限は${result.data.campaign.expired_date}です。</p>`
        }
        if(action === 'register') {
          me._setCampaignCode(campaign_code)
        }
        $("#js-need-to-submit-for-apply").html("変更後新しいキャンペーンコードに変わります。")
        $("#js-submit-membership-plan-check").data("can-submit", "can")
        $(".submit-subscription-update-info").data("can-submit", "can")
        $("#submit_subscription_update_info").removeClass("disabled")
        $("#submit_subscription_update_info").prop("disabled", false)
      }
      if(content != "") {
        $('body').attr('data-has-changed', 'true')
        Popup.alert(content);
      }
    })
  }

  checkChangeCampaignCodeField(elemt) {
    var campaign_code = $(elemt).val();
    $("#js-need-to-submit-for-apply").html("")
    if(campaign_code == '') {
      $("#js-submit-membership-plan-check").data("can-submit", "can")
      $(".submit-subscription-update-info").data("can-submit", "can")
      $('.button-check-campaign').addClass('disabled')
    } else {
      this.hasChangeSetting()
      $("#js-submit-membership-plan-check").data("can-submit", "cannot")
      $(".submit-subscription-update-info").data("can-submit", "cannot")
      $('.button-check-campaign').removeClass('disabled')
    }
  }

  submitMemberShip(elemt) {
    if($(elemt).data("can-submit") == "cannot") {
      $("#remindCheckCode").modal("show")
    } else {
      const submitLink = $("#js-submit-membership-plan")
      if (!!submitLink && submitLink.attr('data-select-plan') === 'true') {
        submitLink[0].click()
      }
    }
  }

  showPlan(element) {
    const $ele = $(element);
    if ($ele.attr('data-show-plans-akapon') === 'true') {
      $ele.text('Akaponプランを選択する')
      $ele.addClass('akaire-functions-color')
      $ele.removeClass('text-primary')
      $ele.attr('data-show-plans-akapon', 'false')
      $('div#title-plan-createcloud').addClass('d-flex').removeClass('d-none')
      $('div#title-plan-akapon').addClass('d-none').removeClass('d-flex')
      $('div#createcloud_plans_list').removeClass('d-none');
      $('div#akapon_plans_list').addClass('d-none');
      // $('div#changePlanAndPaymentTermModal').removeClass('akapon')
    } else {
      $ele.text('Create Cloudプランを選択する')
      $ele.addClass('text-primary')
      $ele.removeClass('akaire-functions-color')
      $ele.attr('data-show-plans-akapon', 'true')
      $('div#title-plan-createcloud').addClass('d-none').removeClass('d-flex')
      $('div#title-plan-akapon').addClass('d-flex').removeClass('d-none')
      $('div#createcloud_plans_list').addClass('d-none');
      $('div#akapon_plans_list').removeClass('d-none');
      // $('div#changePlanAndPaymentTermModal').addClass('akapon')
    }
  }

  showPopupEnterPostalCode(element) {
    const $ele = $(element);
    if (!$ele.hasClass('is-invalid')) {
      return;
    }
    if (!!$ele.val()) {
      Popup.alert(`<a href="${Routes.owner_edit_owner_path()}" target="_blank">「CRM＞会社情報」</a>
    ページの郵便番号を変更すると、<br>申請フォーム「郵便番号」情報が自動的に変更されます。`)
    } else {
      Popup.alert(`<a href="${Routes.owner_edit_owner_path()}" target="_blank">「CRM＞会社情報」</a>
      ページの郵便番号を入力してください。`)
    }
  }

  _showPopupInformToUserNeedVerifyCard() {
    Popup.confirm(
      `
        クレジットカードの仮登録が完了しました。

        ご登録のメールアドレス宛にクレジットカード会社から
        決済についての連絡メールが届きます。
        承認が完了したあと、もう一度お試しください。
      `,
      {
        success: () => { window.location.href = Routes.owner_total_info_path() },
        cancel: () => { window.location.href = Routes.owner_total_info_path() },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto'
      }
    )
  }

  _setCampaignCode(campaignCode) {
    const url = new URL($("#js-submit-membership-plan").attr("href"))
    url.searchParams.set('campaign_code', campaignCode)
    $("#js-submit-membership-plan").attr("href", url)
  }

  hasChangeSetting() {
    $('body').attr('data-has-changed', 'true')
  }

  showCampaignCode(){
    $('#text-campaign-code').text('を入力後、「適用」＞「このプランを選択する」に進んでください。')
    $('.button-show-campaign').toggleClass('d-none');
    $('.group-apply-campaign-code').toggleClass('d-none');
    $('.group-apply-campaign-code').toggleClass('d-flex');
  }

  closeCampaignCode(){
    $('#text-campaign-code').text('を利用する場合は「入力する」をクリックしてください。')
    $('.button-show-campaign').toggleClass('d-none');
    $('.group-apply-campaign-code').toggleClass('d-none');
    $('.group-apply-campaign-code').toggleClass('d-flex');
    $('#campaign_code').val('');
    $('#js-submit-membership-plan-check').data("can-submit", "can");
  }

  handleSettingNotifyOfCompany(element, event) {
    let checkbox = $(element);
    if (checkbox.is(':checked')) {
      this._checkedCheckbox(checkbox, true)
      if (checkbox.val() == 'all_notify') {
        this._handleCheckboxSettingNotify(checkbox, false);
      } else {
        this._handleCheckboxSettingAllNotify(false)
      }
    } else {
      this._checkedCheckbox(checkbox, false)
      if (checkbox.val() == 'all_notify') {
        this._handleCheckboxSettingNotify(checkbox, true);
      } else {
        if ($('input[type="checkbox"][name="receive_notify"]:not(:checked)').length >= 3) {
          this._handleCheckboxSettingAllNotify(true)
        }
      }
    }
    $('#js-will_change_setting_noti_company').removeClass('d-none')
    this._setValueForReceiveNotify()
  }

  _handleCheckboxSettingNotify(checkbox, isChecked) {
    $('input[type="checkbox"][name="receive_notify"]').toArray().forEach((ele) => {
      if ($(ele).val() != checkbox.val()) {
        this._checkedCheckbox($(ele), isChecked)
      }
    });
  }

  _handleCheckboxSettingAllNotify(isChecked) {
    let checkbox = $('input[type="checkbox"][name="receive_notify"][value="all_notify"]')
    this._checkedCheckbox(checkbox, isChecked)
  }

  _checkedCheckbox(element, isChecked) {
    element.prop('checked', isChecked)
    element.parent().find('.checkbox_text').attr('data-content', isChecked ? '✓' : '')
  }

  _setValueForReceiveNotify() {
    let allNotify = $('input[type="checkbox"][name="receive_notify"][value="all_notify"]').is(':checked');
    let onlyImportant = $('input[type="checkbox"][name="receive_notify"][value="only_important"]').is(':checked');
    let notReport = $('input[type="checkbox"][name="receive_notify"][value="not_report"]').is(':checked');
    let inputReceiveNotify = $('input#subscriptions_receive_notify');
    if (allNotify) {
      inputReceiveNotify.val('all_notify');
    } else if (onlyImportant && notReport) {
      inputReceiveNotify.val('only_important_and_not_report');
    } else if (onlyImportant) {
      inputReceiveNotify.val('only_important_and_report');
    } else if (notReport) {
      inputReceiveNotify.val('only_not_report');
    }
    $('body').attr('data-has-changed', 'true');
  }

  showPopupIntroduceReport(event) {
    event.stopPropagation();
    if (gon.is_plan_akapon) {
      Popup.alert(`<div class="fs-20 fs-sp-13">レポートについて</div><br>下記期限の「前日」「当日」「翌日以降」になると毎日AM8時にレポートを受信します。<br><br>・赤入れファイルの有効期限（翌日以降は通知されません）<br>・ファイルの期限（Statusが納品になると通知されません）`)
    } else {
      Popup.alert(`<div class="fs-20 fs-sp-13">レポートについて</div><br>下記期限の「前日」「当日」「翌日以降」になると毎日AM8時にレポートを受信します。<br><br>&nbsp;&nbsp;&nbsp;&nbsp;・赤入れファイルの有効期限（翌日以降は通知されません）<br>&nbsp;&nbsp;&nbsp;&nbsp;・受取った見積書の有効期限（翌日以降は通知されません）<br>・タスクの期限（Statusが納品になると通知されません）<br>&nbsp;&nbsp;&nbsp;・チケットの期限（Statusが納品になると通知されません）`)
    }
    event.preventDefault();
  }
}
