import React, { Component } from 'react';
import { Resizable } from "re-resizable";
import { Button } from 'reactstrap';

export default class ResizableWithControls extends Component {
  constructor() {
    super();
    this.state = {
      height: null,
      isResizing: false,
      dragStartY: null
    };
  }
  handleDragStart = (e) => {
    e.preventDefault();
    const clientY = e.type === 'mousedown' ? e.clientY : e.touches[0].clientY;
    
    this.setState({ 
      isResizing: true,
      dragStartY: clientY 
    });

    if (e.type === 'mousedown') {
      document.addEventListener('mousemove', this.handleDragMove);
      document.addEventListener('mouseup', this.handleDragEnd);
    }
  };

  handleDragMove = (e) => {
    if (this.state.isResizing && this.state.dragStartY !== null) {
      const clientY = e.type === 'mousemove' ? e.clientY : e.touches[0].clientY;
      const deltaY = clientY - this.state.dragStartY;
      const { defaultHeight } = this.props;
      
      this.setState(prevState => ({
        height: Math.max((prevState.height || defaultHeight) + deltaY, 100),
        dragStartY: clientY
      }));
    }
  };
  handleDragEnd = () => {
    this.setState({ 
      isResizing: false,
      dragStartY: null 
    });
    
    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('mouseup', this.handleDragEnd);
  };

  render() {
    const { height, isResizing } = this.state;
    const { defaultHeight, className, children, enable, wrapperClass, isMobile } = this.props;
    return (
      <div className={wrapperClass}>
        <Resizable
          className={className}
          defaultSize={{ height: defaultHeight }}
          size={{ height: height || defaultHeight }}
          enable={ enable }
          sizeStyle={{height: 'min-height: 50px'}}
          onResizeStart={(e) => {
            e.preventDefault();
            this.setState({ isResizing: true });
          }}
          onResizeStop={(e, direction, ref, d) => {
            this.setState({
              height: (height || defaultHeight) + d.height,
              isResizing: false
            });
          }}
        >
          {children}
          { !isMobile && (
              <div className="marker-items-nav position-absolute bottom width-100percent border-t">
                <div className="d-flex justify-content-center align-items-center h-0"
                    onMouseDown={this.handleDragStart}
                    onTouchStart={this.handleDragStart}
                    onTouchMove={this.handleDragMove}
                    onTouchEnd={this.handleDragEnd}
                    onTouchCancel={this.handleDragEnd}
                    style={{
                      userSelect: 'none',
                      WebkitUserSelect: 'none',
                      MozUserSelect: 'none',
                      msUserSelect: 'none',
                      touchAction: 'none'
                    }}
                >
                  <i className="resize-bar mr-2 cursor-ns-resize text-black"></i>
                    <span className='text-black cursor-ns-resize'> 高さ調整バー </span>
                  <i className="resize-bar ml-2 cursor-ns-resize text-black"></i>
                </div>
              </div>
            )
          }
        </Resizable>
      </div>
    );
  }
}
